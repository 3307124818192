import React from "react";
import { COLORS } from "../../../SupportingFiles/colors";
import { Button } from "@mui/material";

interface TabButtonProps {
  onClick?: () => void;
  active?: boolean;
  text: string;
}

const TabButton: React.FC<TabButtonProps> = ({ onClick, active, text }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        flex: "1",
        minWidth: "110px",
        maxWidth: "140px",
        border: "1px solid #000",
        borderRadius: "100px",
        fontSize: "14px",
        fontWeight: "400",
        padding: "10px 24px",
        lineHeight: "16px",
        color: active ? COLORS.white : COLORS.darkgrey,
        textTransform: "capitalize",
        backgroundColor: active ? COLORS.darkgrey : COLORS.white,
        "&:hover": {
          backgroundColor: COLORS.primary,
        },
        "@media (max-width: 768px)": {
          minWidth: "80px",
          maxWidth: "120px",
          fontSize: "12px",
          padding: "6px 12px",
          flex: "auto",
        },
      }}
    >
      {text}
    </Button>
  );
};

export default TabButton;
