import React from "react";
import "./App.scss";
import DaCations from "./Pages/Desktop/DaCations";
import PhotoTourPopup from "./Pages/Mobile/MPhotoTourPopup";
import SinglePhotoView from "./Pages/Mobile/MSinglePhotoView";
import DacationsContainer from "./Container/DacationsContainer";

function App() {
  return (
    <div className="App">
      <DacationsContainer />
    </div>
  );
}

export default App;
