import React, { useState } from "react";
import useIsMobile from "../SupportingFiles/MobileProvider";
import MDaCations from "../Pages/Mobile/MDaCations";
import DaCations from "../Pages/Desktop/DaCations";

export interface Facility {
  name: string;
  iconUrl: string;
}
export interface Rules {
  desc: string;
}
export interface Policy {
  desc: string;
}
export interface Meals {
  title: string;
  desc: string;
}
export interface Reviews {
  name: string;
  designation: string;
  imgUrl: string;
  feedbackTitle: string;
  feedback: string;
  rating: number;
}
export type TSpaces = { image: string; title: string; data: Array<string> };

const DacationsContainer = () => {
  const isMobile = useIsMobile();
  const [isModifyDetailModalOpen, setModifyDetailModalOpen] = useState(false);
  const [formData, setFormData] = useState<{
    checkIn: { value: Date | null | any; warning: boolean };
    checkOut: { value: string | null | Date | any; warning: boolean };
    noOfRooms: { value: string | any; warning: boolean };
    noOfGuests: { value: string | any; warning: boolean };
  }>({
    checkIn: { value: "", warning: false },
    checkOut: { value: "", warning: false },
    noOfRooms: { value: "", warning: false },
    noOfGuests: {
      value: "",
      warning: false,
    },
  });

  const handleModifyDetailOpenModal = () => {
    setModifyDetailModalOpen(true);
  };
  const handleModifyDetailCloseModal = () => {
    setModifyDetailModalOpen(false);
  };
  const shareLink = () => {
    const currentUrl = window.location.href; // Get the complete URL
    const message = encodeURIComponent(
      `Check out - Salud by dacations: ${currentUrl}`
    );
    const whatsappUrl = `https://wa.me/?text=${message}`;

    // Open WhatsApp share link directly in a new window
    window.open(whatsappUrl, "_blank");
  };

  // Share Booking details
  const openWhatsApp = () => {
    const phoneNumber = "6283947545";
    console.log("checkIn value:", formData.checkIn.value);
    console.log("checkOut value:", formData.checkOut.value);
    const message = encodeURIComponent(
      `Hi, I would like to book Salud ${formData.checkIn.value} to ${formData.checkOut.value}. for Guestcount ${formData.noOfGuests.value} and ${formData.noOfRooms.value}. Please let me know the details!`
    );

    // Form the WhatsApp URL with the phone number and pre-filled message
    const url = `https://wa.me/${phoneNumber}?text=${message}`;

    // Open WhatsApp in a new tab
    window.open(url, "_blank");
  };

  const noofRooms = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
  ];
  const noofguests = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
    { value: 11, label: "11" },
  ];

  console.log(formData, "formData");

  // Define the facilities array
  const handleChangeReserve: any = (event: any, name: any) => {
    let warning = false;
    if (name === "checkIn") {
      setFormData((pre: any) => ({
        ...pre,
        checkIn: {
          value: event,
          warning: warning, // Set warning based on conditions
        },
      }));
    } else if (name === "checkOut") {
      setFormData((pre: any) => ({
        ...pre,
        checkOut: {
          value: event,
          warning: warning, // Set warning based on conditions
        },
      }));
    } else {
      setFormData((pre: any) => ({
        ...pre,
        [event.target.name]: {
          value: event.target.value,
          warning: warning, // Set warning based on conditions
        },
      }));
    }
  };
  console.log("formData", formData);
  const validate_form = () => {
    let data = { ...formData };
    data = {
      ...data,
      checkIn: {
        ...data.checkIn,
        warning: !data.checkIn.value,
      },
      checkOut: {
        ...data.checkOut,
        warning: !data.checkOut.value,
      },
      noOfGuests: {
        ...data.noOfGuests,
        warning: !data.noOfGuests.value,
      },
      noOfRooms: {
        ...data.noOfRooms,
        warning: !data.noOfRooms.value,
      },
    };
    setFormData(data);

    const hasErrors =
      data.checkIn.warning ||
      data.checkOut.warning ||
      data.noOfGuests.warning ||
      data.noOfRooms.warning;

    if (!hasErrors) {
      openWhatsApp();
    }
  };

  const faqs = [
    {
      id: "panel1",
      question: "When can I be sure my booking is confirmed?",
      answer:
        "Your booking is 100% confirmed only after you’ve made the full payment and received an email confirming your stay in response.",
    },
    {
      id: "panel2",
      question: "Is the villa suitable for a day picnic?",
      answer:
        "Yes, the place can possibly be booked for a day picnic at a subsidized cost. Please write to us at booking@dacations.in with your details, and we'll be happy to help you!",
    },
    {
      id: "panel3",
      question: "Can I reserve my villa for a few days?",
      answer: "Yes, You can",
    },
    {
      id: "panel4",
      question: "Is smoking allowed here?",
      answer: "Smoking is allowed in exterior areas of the villa.",
    },
    {
      id: "panel5",
      question: "Is smoking allowed here?",
      answer: "Smoking is allowed in exterior areas of the villa.",
    },
  ];

  const explore_your_stay = (
    <>
      <p>
        🌟 Unwind, Recharge and Rediscover at Salud ! 🌟 Nestled in the serene
        Shivalik Hills. Salud combines rustic luxury with bohemian
        charm.creating an oasis of relaxation and adventure for those seeking to
        escape the mundane. With its enchanting backdrop of natural splendor,
        Salud is not just a stay; it's an experience that etches itself into the
        heart of every visitor. Discover the epitome of comfort and style in our
        four bespoke en-suite rooms, each a testament to luxury and personalized
        comfort. From the plush bedding to the modern amenities, every detail is
        crafted to ensure your stay is nothing short of extraordinary.
      </p>
      <br />
      <p>
        Our heated pool awaits to envelop you in its soothing embrace, offering
        a tranquil retreat to unwind and recharge. Whether you wish to take a
        leisurely swim under the sky or simply lounge by the poolside, serenity
        is always a splash away.
      </p>
      <br />
      <p>
        Step outside and immerse yourself in the beauty of our expansive gardens
        and play areas, designed for moments of joy and relaxation. For the
        adventurous at heart, the surrounding Shivalik Hills beckon with treks
        and outdoor sports, offering an unforgettable blend of thrill and
        natural beauty.
      </p>
      <br />
      <p>
        As the sun sets, gather around the warm glow of our bonfires and enjoy
        gourmet BBQs under a canopy of stars. It's in these magical moments that
        memories are made, stories are shared, and the essence of Salud comes
        alive.
      </p>
      <br />
      <p>
        At Salud By Dacations, we invite you to unwind, recharge, and rediscover
        yourself in an environment where luxury meets nature. Embark on a
        journey where each moment is a celebration of life's simple pleasures
        and the unparalleled beauty of the Shivalik Hills.
      </p>
      <br />
      <p>Welcome to Salud, where your dream retreat awaits.</p>
    </>
  );

  const facilities: Facility[] = [
    { name: "Heated Swimming Pool", iconUrl: "./images/pool-icon.svg" },
    { name: "Gazebo", iconUrl: "./images/gazebo-icon.svg" },
    { name: "Meals Available", iconUrl: "./images/meal-icon.svg" },
    { name: "Wifi", iconUrl: "./images/wifi-icon.svg" },
    { name: "Badminton", iconUrl: "./images/badminton-icon.svg" },
  ];
  const rules: Rules[] = [
    { desc: "There are a total of 4 bedrooms on the property." },
    {
      desc: "There is an all weather temperature controlled  swimming pool at the villa water temperature will be maintained at 28 C. ",
    },
    {
      desc: "Guested are requested to follow the pool rules and guidelines.",
    },
    {
      desc: "There is a pet dog at the property , he is well trained and friendly.",
    },
    {
      desc: "During the monsoon season, the house may be prone to common bugs and insects. As a preventive measure, please keep the bedroom doors shut during your stay",
    },
    { desc: "The last 1 km. leading up to the villa is an unbuilt road. " },
    { desc: "All-male groups are not allowed." },
    { desc: "This is a pet-friendly villa." },
    {
      desc: "In case of a power outage, the 25KVA generator can support all lights and plug points . If the generator is running for more than 8  hours, an additional cost of Rs. 1000 per hour will be levied.",
    },
    { desc: "All mobile networks work fairly well here." },
    { desc: "Please be mindful and keep noise to a minimum after 10 PM." },
    { desc: "Loud Music is not allowed after 10.30 PM" },
    {
      desc: "Guests can stay connected with complimentary Wi-Fi. Network is subject to availability at any given time.",
    },
    { desc: "The villa is located in a secluded area." },
    {
      desc: "There are CCTV cameras that capture the external areas of the property for security purposes.",
    },
    {
      desc: "Guests are advised to stay cautious of wild animals around the property.",
    },
    { desc: "The caretaker resides on the premises of the property." },
    {
      desc: "Since the property is situated in a rustic setting, guests are requested to respect nature and the locals.",
    },
    { desc: "This is a standalone property." },
    { desc: "Alcohol consumption is allowed. " },
    { desc: "Smoking is allowed in the exterior areas of the villa only." },
    {
      desc: "Children should be supervised while they are in or around the pool.",
    },
    { desc: "Guests are earnestly requested to treat the home with care." },
  ];
  const policy: Policy[] = [
    {
      desc: "The full payment must be made for your booking to be confirmed.",
    },
    {
      desc: "The number of guests must not exceed the count mentioned at the time of booking.",
    },
    {
      desc: "Only the guests who have been accounted for are allowed at the villa.",
    },
    {
      desc: "All guests must be able to share valid ID proofs when asked. This could be at the time of booking, and also upon arrival.",
    },
    {
      desc: "All foreign nationals must be able to share their passport and visa details prior to their stay.",
    },
    {
      desc: "Any damage to the property caused by the guest will be charged as per the actual cost of repair or replacement.",
    },
    {
      desc: "Any illegal activity - including but not limited to prostitution and the use of narcotics - is strictly not permitted.",
    },
    { desc: "Any commercial activity is strictly not permitted." },
    { desc: "A refundable deposit may be collected before check-in." },
    {
      desc: "This entire amount is refunded within 5 working days, provided the total number of guests does not increase and the check-out time is adhered to.",
    },
    {
      desc: "In case of any delays or other grievances, guests can reach out to guestsupport@dacations.in for assistance.",
    },
  ];
  const Reviews: Reviews[] = [
    {
      name: "Pukhraj",
      designation: "Guest",
      feedbackTitle: "Experience was great",
      imgUrl: "./images/ankit-sachdeva.jpg",
      rating: 4,
      feedback:
        "A new property. Not too far from Chandigarh.It's a beautiful property well made with nice rustic interiors.The heated pool is an attraction. Staff is helpful and very active. Especially Rajesh made great efforts and Nikhil was also quite available and responsive. The owners are available on call and helpful.",
    },
    {
      name: "Sunil Juneja",
      designation: "Guest",
      feedbackTitle: "Family Reunion Paradise",
      imgUrl: "./images/sunil-juneja.jpg",
      rating: 4,
      feedback:
        "Our family reunion at dacations was a huge success, thanks to the fantastic accommodations and impeccable service. The villa offered something for everyone, from the kids' play area to the adults' relaxation spaces. We can't wait to come back next year!",
    },
    {
      name: "Suveshi Sharma",
      designation: "Guest",
      feedbackTitle: "A Magical Escape",
      imgUrl: "./images/suveshi.jpg",
      rating: 5,
      feedback:
        "Our stay at dacations was nothing short of magical. From the breathtaking views to the luxurious amenities, every moment felt like a dream. The villa's staff went above and beyond to ensure our comfort, and we can't wait to return.",
    },
    {
      name: "Rahul Raghuvanshi",
      designation: "Guest",
      feedbackTitle: "Luxury Redefined",
      imgUrl: "./images/rahul.jpg",
      rating: 5,
      feedback:
        "Choosing dacations for our vacation was the best decision we made. The spacious layout, stunning pool, and proximity to local attractions made our stay unforgettable. We created memories here that will last a lifetime.",
    },
  ];
  const Spaces: TSpaces[] = [
    {
      image: "./images/bedroom-1.webp",
      title: "Bedroom 1",
      data: [
        "This is a spacious bedroom situated on the ground floor of the property.",
        "The bedroom has been designed in bohemian architecture with stone work and rustic furniture",
        "This bedroom opens up directly to our amazing pool.",
        "The room offers a comfortable king-sized bed, AC, Wi-Fi, wardrobes and a window that opens up to a beautiful view of the pool.",
        "It has an ensuite bathroom with hot water, towels, bathup & toiletries.",
      ],
    },
    {
      image: "./images/bedroom-2.webp",
      title: "Bedroom 2",
      data: [
        "This is a bedroom with a mountain view",
        "The bedroom is has lovely minimalistic design",
        "The ground-floor bedroom offers a serene retreat with a balcony and cozy seating.",
        "It comes fully equipped with essential amenities including an AC, queen-sized bed, Wi-Fi, and ample wardrobe space.",
        "The attached bathroom features modern fittings including hot water, towels, bathtub, and toiletries for added convenience.",
      ],
    },
    {
      image: "./images/bedroom.webp",
      title: "Bedroom 3",
      data: [
        "This bedroom has one of the most unique themes with dark interiors and natural material",
        "This is a spacious bedroom situated on the first floor of the villa.",
        "This is room has a beautiful view of the valley and the pool",
        "The room offers a comfortable king-sized bed, AC, Wi-Fi, wardrobes and a window that opens up to a beautiful view of pool.",
        "It has an ensuite bathroom with hot water, towels, bathtub, and toiletries.",
      ],
    },
    {
      image: "./images/bedroom-13.webp",
      title: "Bedroom 4",
      data: [
        "Beautiful room with unique design and a direct access to the terrace on one side and balcony on the other ",
        "This is a spacious bedroom situated on the first floor of the property.",
        "This is room has a beautiful view of the valley and the pool",
        "The room offers a comfortable king-sized bed, AC, Wi-Fi, wardrobes and a window that opens up to a beautiful view of pool.",
        "It has an ensuite bathroom with a hot water,bathtub, towels, and  toiletries.",
      ],
    },
    {
      image: "./images/pool-4.webp",
      title: "Heated Pool",
      data: [
        "A serene pool awaits you, offering shimmering waters perfect for relaxing with their favorite beverage. ",
        "The temprature of the pool is maintained at a cosy 28 Degrees",
        "Our Pool is private and has a depth of 3.5 feet to 4.5 feet.",
        "Here you will get the view of beatiful valleys of shivalik hills & out expansive gardens",
      ],
    },
    {
      image: "./images/living-room.webp",
      title: "Living Room",
      data: [
        "Painted in warm and inviting colors, the living room offers a cozy ambiance for relaxation and entertainment.",
        "Enhanced with modern amenities including a 52-inch TV, air conditioning, WiFi, and a home theatre system, it ensures a delightful experience for guests.",
      ],
    },
  ];
  const meals: Meals[] = [
    {
      title: "Savor the Flavors at Salud",
      desc: "Embark on a culinary journey with Salud, where every meal is an exquisite restaurant-quality experience.",
    },
    {
      title: "Taste the World at Salud",
      desc: "Our diverse menu caters to all tastes and preferences, featuring a lavish spread of Indian, Chinese, and Continental cuisines..",
    },
    {
      title: "Barbecue Experiences",
      desc: "Yearning for something a little different? Indulge in our personalized barbecue arrangements, perfect for those seeking a unique dining experience under the stars..",
    },
    {
      title: "Crafted Just for You",
      desc: "To ensure we cater to your specific tastes, please share your meal choices with us in advance, allowing a minimum of 48 hours notice before your arrival..",
    },
    {
      title: "Your Diet, Our Menu",
      desc: "Whatever your dietary needs—health, lifestyle, or ethical—we're ready to customize meals just for you. Let us know in advance..",
    },
    {
      title: "Transparent Pricing",
      desc: "All our food and beverage offerings are subject to the applicable GST charge, ensuring no surprises.",
    },
    {
      title: "Ready to Dine with Us?",
      desc: "Let Salud take you on a gastronomic journey that you'll remember long after your stay. Bon Appetit!.",
    },
  ];

  return (
    <>
      {isMobile ? (
        <MDaCations
          handleModifyDetailOpenModal={handleModifyDetailOpenModal}
          handleModifyDetailCloseModal={handleModifyDetailCloseModal}
          isModifyDetailModalOpen={isModifyDetailModalOpen}
          Spaces={Spaces}
          Reviews={Reviews}
          meals={meals}
          explore_your_stay={explore_your_stay}
          facilities={facilities}
          policy={policy}
          rules={rules}
          faqs={faqs}
          formData={formData}
          setFormData={setFormData}
          noofRooms={noofRooms}
          noofguests={noofguests}
          handleChangeReserve={handleChangeReserve}
          validate_form={validate_form}
          openWhatsApp={openWhatsApp}
          shareLink={shareLink}
        />
      ) : (
        <DaCations
          handleModifyDetailOpenModal={handleModifyDetailOpenModal}
          handleModifyDetailCloseModal={handleModifyDetailCloseModal}
          isModifyDetailModalOpen={isModifyDetailModalOpen}
          Spaces={Spaces}
          Reviews={Reviews}
          meals={meals}
          explore_your_stay={explore_your_stay}
          facilities={facilities}
          policy={policy}
          rules={rules}
          faqs={faqs}
          formData={formData}
          setFormData={setFormData}
          noofRooms={noofRooms}
          noofguests={noofguests}
          handleChangeReserve={handleChangeReserve}
          validate_form={validate_form}
          openWhatsApp={openWhatsApp}
          shareLink={shareLink}
        />
      )}
    </>
  );
};

export default DacationsContainer;
