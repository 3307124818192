import * as React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers";
import subDays from "date-fns/subDays";
import subMonths from "date-fns/subMonths";
import subYears from "date-fns/subYears";
import { useEffect, useState } from "react";
import {
  FORMAT_DD_MM_YYYY,
  isEmpty,
} from "../../../SupportingFiles/HelpingFunction";

const DatePickerInput = ({
  title,
  value,
  value_update,
  attrName,
  error_message = "Select",
  warn_status,
  class_name,
  min_date = 15,
  max_date = 18,
  date_validation_type = "YEARS",
  disabled = false,
  default_date = new Date(),
  name,
  sx,
}: {
  title?: string;
  value?: any;
  attrName?: any;
  value_update: Function;
  error_message?: string;
  warn_status?: boolean;
  class_name: string;
  min_date?: number;
  max_date?: number;
  date_validation_type?: "YEARS" | "MONTHS" | "DAYS";
  disabled?: boolean;
  default_date?: Date;
  name?: any;
  sx?: any;
}) => {
  const [minDate, setMinDate] = useState<Date>(subYears(new Date(), min_date));
  const [maxDate, setMaxDate] = useState<Date>(subYears(new Date(), max_date));
  const [dateValidation, setDateValidation] = useState<boolean>(true);
  const [localValue, setLocalValue] = useState<Date | null>(
    value ? new Date(value) : null
  );

  // Update minDate and maxDate based on date validation type
  useEffect(() => {
    switch (date_validation_type) {
      case "YEARS":
        setMinDate(subYears(new Date(), min_date));
        setMaxDate(subYears(new Date(), max_date));
        break;
      case "MONTHS":
        setMinDate(subMonths(new Date(), min_date));
        setMaxDate(subMonths(new Date(), max_date));
        break;
      case "DAYS":
        setMinDate(subDays(new Date(), min_date));
        setMaxDate(subDays(new Date(), max_date));
        break;
      default:
        break;
    }
  }, [min_date, max_date, date_validation_type]);

  // Handle changes in the 'value' prop
  useEffect(() => {
    if (isEmpty(value)) {
      // Handle null or empty string case
      setLocalValue(null);
    } else {
      const dateObject = new Date(value);
      if (!isNaN(dateObject.getTime())) {
        // Valid date
        setLocalValue(dateObject);
      } else {
        // Invalid date string
        console.error("Invalid date string:", value);
      }
    }
  }, [value]);

  return (
    <div className={`datepicker ${class_name}`} style={sx}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          disabled={disabled}
          label={title}
          value={localValue}
          onChange={(selectedDate: any) => {
            setLocalValue(selectedDate);
            value_update(attrName, FORMAT_DD_MM_YYYY(selectedDate));
          }}
          closeOnSelect
          defaultCalendarMonth={default_date}
          minDate={dateValidation ? minDate : null}
          maxDate={dateValidation ? maxDate : null}
          sx={{
            width: "100%",
          }}
        />
        {warn_status ? <span className="error">{error_message}</span> : null}
      </LocalizationProvider>
    </div>
  );
};

export default DatePickerInput;
