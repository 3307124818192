import * as React from "react";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { COLORS } from "../../../SupportingFiles/colors";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import "./Popup.scss";
import { Box } from "@mui/material";

const closeButtonStyle = {
  minWidth: "40px",
};

export default function Popup({
  open,
  onClose,
  children,
  class_name,
}: {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  class_name: string;
}) {
  const dynamicModalStyle = {
    padding: "100px 0px 32px",
    width: "100%",
  };

  const [scroll, setScroll] = React.useState<"body">("body");

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className={`popupDialog ${class_name}`}
      >
        <DialogContent dividers={false} sx={dynamicModalStyle}>
          <Box sx={{}} className="closeButton">
            <Button style={closeButtonStyle} onClick={onClose}>
              <KeyboardArrowLeftIcon
                sx={{ color: COLORS.darkgrey, fontSize: "32px" }}
              />
            </Button>
          </Box>

          {children}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
