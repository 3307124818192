import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Box, Button, TextField } from "@mui/material";
import { COLORS } from "../../../SupportingFiles/colors";
import Buttons from "../../InputFields/Buttons/Buttons";
import Popup from "../../Desktop/Popup/Popup";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

function Navbar() {
  const openWhatsApp = () => {
    const phoneNumber = "+916283947545";
    // Replace 'Hello! Welcome to our WhatsApp chat.' with your desired welcome message
    const message = encodeURIComponent(
      "Hi! I was checking out Salud by dacations online."
    );

    // Form the WhatsApp URL with the phone number and pre-filled message
    const url = `https://wa.me/${phoneNumber}?text=${message}`;

    // Open WhatsApp in a new tab
    window.open(url, "_blank");
  };

  const [isHelpModalOpen, setHelpModalOpen] = useState(false);

  const handleHelpOpenModal = () => {
    setHelpModalOpen(true);
  };
  const handleHelpCloseModal = () => {
    setHelpModalOpen(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: COLORS.white,
        borderBottom: "1px solid",
        borderBottomColor: COLORS.primary,
        padding: "8px 0px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        top: "0px",
        zIndex: "991",
      }}
    >
      <Grid
        container
        spacing={3}
        alignItems="center"
        maxWidth="100%"
        width="100%"
      >
        <Grid xs={4}>
          <img
            src="./images/salud-logo.svg"
            height="42px"
            alt="Salud by DaCations"
          />
        </Grid>
        <Grid xs={8} justifyContent="end" display="flex">
          <Button
            sx={{
              border: "1px solid #25CC64",
              borderRadius: "8px",
              padding: "8px",
              color: "#25CC64",
              minWidth: "20px",
              minHeight: "20px",
              marginRight: "20px",
            }}
            onClick={openWhatsApp}
          >
            <WhatsAppIcon style={{ height: "16px", width: "16px" }} />
          </Button>
          <Button
            sx={{
              border: "1px dashed #000",
              borderRadius: "8px",
              fontSize: "14px",
              fontWeight: "600",
              padding: "8px 16px",
              lineHeight: "16px",
              color: COLORS.black,
              textTransform: "capitalize",
            }}
            onClick={handleHelpOpenModal}
          >
            Need Help?
          </Button>
          <Popup
            open={isHelpModalOpen}
            onClose={handleHelpCloseModal}
            class_name="smallView"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "80px",
                maxWidth: "1200px",
                width: "100%",
                margin: "0px auto",
              }}
            >
              <Grid container spacing={3} alignItems="flex-start" width="100%">
                <Grid xs={12}>
                  <h4>Need Help?</h4>
                  <p>We are happy to help you</p>
                </Grid>
                <Grid xs={12}>
                  <TextField
                    id="outlined-basic"
                    label="Mobile Number"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} textAlign="center">
                  <Buttons text="Request Call Back" onClick={() => {}} />
                </Grid>
              </Grid>
            </Box>
          </Popup>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Navbar;
