import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useState } from "react";
import "react-multi-carousel/lib/styles.css";
import Popup from "../../Components/Desktop/Popup/Popup";
import SinglePhotoView from "./SinglePhotoView";

interface PhotoTour {
  name: string;
  imgUrl: string;
}
const PhotoTourPopup = ({ open, onclose }: { open: any; onclose: any }) => {
  // View Photo Tour Popup
  const [isModifyDetailModalOpen, setModifyDetailModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const handleModifyDetailOpenModal = (index: number) => {
    setSelectedImageIndex(index);
    setModifyDetailModalOpen(true);
  };
  const handleModifyDetailCloseModal = () => {
    setModifyDetailModalOpen(false);
  };

  const photoTour: PhotoTour[] = [
    { name: "Living Room", imgUrl: "./images/living-room.webp" },
    { name: "Dining Area", imgUrl: "./images/dining-area.webp" },
    { name: "Bedroom 1", imgUrl: "./images/bedroom-1.webp" },
    { name: "Bedroom 2", imgUrl: "./images/bedroom-2.webp" },
    { name: "Bedroom 3", imgUrl: "./images/bedroom-3.webp" },
    { name: "Bedroom 4", imgUrl: "./images/bedroom-4.webp" },
    { name: "Bathroom 1", imgUrl: "./images/full-bathroom-1.webp" },
    { name: "Bathroom 2", imgUrl: "./images/full-bathroom-2.webp" },
    { name: "Bathroom 3", imgUrl: "./images/full-bathroom-3.webp" },
    { name: "Bathroom 4", imgUrl: "./images/full-bathroom-4.webp" },
    { name: "Balcony", imgUrl: "./images/balcony.webp" },
    { name: "Terrace", imgUrl: "./images/terrace.webp" },
    { name: "Pool", imgUrl: "./images/pool.webp" },
    { name: "Additional Photos", imgUrl: "./images/additional-photo.webp" },
  ];

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Popup open={open} onClose={onclose} class_name="">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          // gap: "80px",
          maxWidth: "1200px",
          width: "100%",
          margin: "0px auto",
        }}>
        <Grid container spacing={3} alignItems="flex-start" width="100%">
          <Grid xs={12}>
            <h4>Photo Tour</h4>
          </Grid>
          <Grid xs={12}>
            <ul style={{ display: "flex", flexWrap: "wrap", gap: "24px" }}>
              {photoTour.map((PhotoTour, index) => (
                <div key={index} id={`${index}`}>
                  <li
                    style={{
                      textAlign: "center",
                      minWidth: "128px",
                      maxWidth: "150px",
                      listStyle: "none",
                      cursor: "pointer",
                      flex: "1",
                    }}
                    onClick={() => scrollToSection(PhotoTour.name)}>
                    <Box
                      borderRadius="20px"
                      overflow="hidden"
                      marginBottom="12px"
                      height="154px">
                      <img
                        src={PhotoTour.imgUrl}
                        width="100%"
                        height="100%"
                        style={{ objectFit: "cover" }}
                        alt="img"
                      />
                    </Box>
                    <p>{PhotoTour.name}</p>
                  </li>
                </div>
              ))}
            </ul>
          </Grid>
        </Grid>

        {/* Living room */}
        <Grid
          // ref={sectionRefs[0]}
          container
          spacing={3}
          width="100%"
          id="Living room"
          paddingTop={"80px"}>
          <Grid xs={12} md={4}>
            <h3>Living room</h3>
            <p>
              Air conditioning, · Board games, · Books and reading material, ·
              Ethernet connection, · Heating, · Indoor fireplace, · Sound
              system, · TV
            </p>
          </Grid>
          <Grid xs={12} md={8}>
            <Box className="photoContainer">
              <Box
                className="oneHalf"
                onClick={() => handleModifyDetailOpenModal(0)}>
                <img src="./images/living-room.webp" />
              </Box>
              <Box
                className="oneHalf2"
                onClick={() => handleModifyDetailOpenModal(1)}>
                <img src="./images/living-room-3.webp" />
              </Box>
              <Box
                className="oneHalfThird"
                onClick={() => handleModifyDetailOpenModal(2)}>
                <img src="./images/living-room-2.webp" />
              </Box>
              {isModifyDetailModalOpen && (
                <SinglePhotoView
                  onclose={handleModifyDetailCloseModal}
                  selectedImageIndex={selectedImageIndex}
                />
              )}
            </Box>
          </Grid>
        </Grid>

        {/* Dining Area */}
        <Grid
          // ref={sectionRefs[1]}
          container
          spacing={3}
          width="100%"
          id="Dining Area"
          paddingTop={"80px"}>
          <Grid xs={12} md={4}>
            <h3>Dining Area</h3>
            <p>
              Air conditioning · Dining table · Heating · Wine glasses ·
              Barbecue utensils · Coffee maker
            </p>
          </Grid>
          <Grid xs={12} md={8}>
            <Box display="flex" gap="24px">
              <Box
                className="oneHalf"
                onClick={() => handleModifyDetailOpenModal(3)}>
                <img src="./images/dining-area-1.webp" />
              </Box>
              <Box
                className="oneHalf2"
                onClick={() => handleModifyDetailOpenModal(4)}>
                <img src="./images/dining-area-2.webp" />
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Bedroom 1 */}
        <Grid
          // ref={sectionRefs[2]}
          container
          spacing={3}
          width="100%"
          id="Bedroom 1"
          paddingTop={"80px"}>
          <Grid xs={12} md={4}>
            <h3>Bedroom 1</h3>
            <p>
              King bed, · Floor mattress, · Air conditioning, · Bed linen, ·
              Clothes storage, · Essentials, · Extra pillows and blankets, ·
              Hangers, · Heating, · Room-darkening blinds, · Iron
            </p>
          </Grid>
          <Grid xs={12} md={8}>
            <Box className="photoContainer">
              <Box
                className="oneHalf"
                onClick={() => handleModifyDetailOpenModal(5)}>
                <img src="./images/bedroom-1.webp" />
              </Box>
              <Box
                className="oneHalf2"
                onClick={() => handleModifyDetailOpenModal(6)}>
                <img src="./images/bedroom-5.webp" />
              </Box>
              <Box
                className="oneHalfThird"
                onClick={() => handleModifyDetailOpenModal(7)}>
                <img src="./images/bedroom-6.webp" />
              </Box>
              <Box
                className="fullWidthCol"
                onClick={() => handleModifyDetailOpenModal(8)}>
                <img src="./images/bedroom-7.webp" />
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Bedroom 2 */}
        <Grid
          // ref={sectionRefs[3]}
          container
          spacing={3}
          width="100%"
          id="Bedroom 2"
          paddingTop={"80px"}>
          <Grid xs={12} md={4}>
            <h3>Bedroom 2</h3>
            <p>
              King bed, · Air conditioning, · Bed linen, · Clothes storage, ·
              Essentials, · Hangers, · Heating, · Iron, · Room-darkening blinds
            </p>
          </Grid>
          <Grid xs={12} md={8}>
            <Box className="photoContainer">
              <Box
                className="oneHalf"
                onClick={() => handleModifyDetailOpenModal(9)}>
                <img src="./images/bedroom-2.webp" />
              </Box>
              <Box
                className="oneHalf2"
                onClick={() => handleModifyDetailOpenModal(10)}>
                <img src="./images/bedroom-8.webp" />
              </Box>
              <Box
                className="oneHalfThird"
                onClick={() => handleModifyDetailOpenModal(11)}>
                <img src="./images/bedroom-9.webp" />
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Bedroom 3 */}
        <Grid
          // ref={sectionRefs[4]}
          container
          spacing={3}
          width="100%"
          id="Bedroom 3"
          paddingTop={"80px"}>
          <Grid xs={12} md={4}>
            <h3>Bedroom 3</h3>
            <p>
              King bed, · Floor mattress, · Air conditioning, · Bed linen, ·
              Clothes storage, · Extra pillows and blankets, · Hangers, ·
              Heating, · Room-darkening blinds, · Iron
            </p>
          </Grid>
          <Grid xs={12} md={8}>
            <Box className="photoContainer">
              <Box
                className="fullWidthCol"
                onClick={() => handleModifyDetailOpenModal(12)}>
                <img src="./images/bedroom-3.webp" />
              </Box>
              <Box
                className="oneHalf"
                onClick={() => handleModifyDetailOpenModal(13)}>
                <img src="./images/bedroom.webp" />
              </Box>
              <Box
                className="oneHalf2"
                onClick={() => handleModifyDetailOpenModal(14)}>
                <img src="./images/bedroom-10.webp" />
              </Box>
              <Box
                className="oneHalfThird"
                onClick={() => handleModifyDetailOpenModal(15)}>
                <img src="./images/bedroom-11.webp" />
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Bedroom 4 */}
        <Grid
          // ref={sectionRefs[5]}
          container
          spacing={3}
          width="100%"
          id="Bedroom 4"
          paddingTop={"80px"}>
          <Grid xs={12} md={4}>
            <h3>Bedroom 4</h3>
            <p>
              King bed, · Floor mattress, · Air conditioning, · Bed linen, ·
              Clothes storage, · Essentials, · Extra pillows and blankets, ·
              Hangers, · Iron, · Room-darkening blinds
            </p>
          </Grid>
          <Grid xs={12} md={8}>
            <Box className="photoContainer">
              <Box
                className="oneHalf"
                onClick={() => handleModifyDetailOpenModal(16)}>
                <img src="./images/bedroom-13.webp" />
              </Box>
              <Box
                className="oneHalf2"
                onClick={() => handleModifyDetailOpenModal(17)}>
                <img src="./images/bedroom-14.webp" />
              </Box>
              <Box
                className="oneHalfThird"
                onClick={() => handleModifyDetailOpenModal(18)}>
                <img src="./images/bedroom-12.webp" />
              </Box>
              <Box
                className="halfCol"
                onClick={() => handleModifyDetailOpenModal(19)}>
                <img src="./images/bedroom-15.webp" />
              </Box>
              <Box
                className="halfCol2"
                onClick={() => handleModifyDetailOpenModal(20)}>
                <img src="./images/bedroom-16.jpeg" />
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Bathroom 1 */}
        <Grid
          // ref={sectionRefs[6]}
          container
          spacing={3}
          width="100%"
          id="Bathroom 1"
          paddingTop={"80px"}>
          <Grid xs={12} md={4}>
            <h3> Bathroom 1</h3>
            <p>
              Air conditioning, · Bath, · Body soap, · Cleaning products, ·
              Conditioner, · Hair dryer, · Hot water, · Shampoo, · Shower gel
            </p>
          </Grid>
          <Grid xs={12} md={8}>
            <Box className="photoContainer">
              <Box
                className="fullWidthCol"
                onClick={() => handleModifyDetailOpenModal(21)}>
                <img src="./images/full-bathroom-1.webp" />
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Bathroom 2 */}
        <Grid
          // ref={sectionRefs[7]}
          container
          spacing={3}
          width="100%"
          id="Bathroom 2"
          paddingTop={"80px"}>
          <Grid xs={12} md={4}>
            <h3> Bathroom 2</h3>
            <p>
              Air conditioning · Bath, · Body soap · Cleaning products ·
              Conditioner · Hair dryer · Hot water · Shampoo · Shower gel
            </p>
          </Grid>
          <Grid xs={12} md={8}>
            <Box className="photoContainer">
              <Box
                className="halfCol"
                onClick={() => handleModifyDetailOpenModal(22)}>
                <img src="./images/full-bathroom-2.webp" />
              </Box>
              <Box
                className="halfCol2"
                onClick={() => handleModifyDetailOpenModal(23)}>
                <img src="./images/full-bathroom-5.webp" />
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Bathroom 3 */}
        <Grid
          // ref={sectionRefs[8]}
          container
          spacing={3}
          width="100%"
          id="Bathroom 3"
          paddingTop={"80px"}>
          <Grid xs={12} md={4}>
            <h3> Bathroom 3</h3>
            <p>
              Bath · Air conditioning · Body soap · Conditioner · Hair dryer ·
              Hot water · Shower gel · Shampoo
            </p>
          </Grid>
          <Grid xs={12} md={8}>
            <Box className="photoContainer">
              <Box
                className="oneHalfThird"
                onClick={() => handleModifyDetailOpenModal(24)}>
                <img src="./images/full-bathroom-3.webp" />
              </Box>
              <Box
                className="oneHalf"
                onClick={() => handleModifyDetailOpenModal(25)}>
                <img src="./images/full-bathroom-6.webp" />
              </Box>
              <Box
                className="oneHalf2"
                onClick={() => handleModifyDetailOpenModal(26)}>
                <img src="./images/full-bathroom-7.webp" />
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Bathroom 4 */}
        <Grid
          // ref={sectionRefs[9]}
          container
          spacing={3}
          width="100%"
          id="Bathroom 4"
          paddingTop={"80px"}>
          <Grid xs={12} md={4}>
            <h3> Bathroom 4</h3>
            <p>
              Air conditioning · Dining table · Heating · Wine glasses ·
              Barbecue utensils · Coffee maker
            </p>
          </Grid>
          <Grid xs={12} md={8}>
            <Box className="photoContainer">
              <Box
                className="halfCol"
                onClick={() => handleModifyDetailOpenModal(27)}>
                <img src="./images/full-bathroom-4.webp" />
              </Box>
              <Box
                className="halfCol2"
                onClick={() => handleModifyDetailOpenModal(28)}>
                <img src="./images/full-bathroom-8.webp" />
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Balcony */}
        <Grid
          // ref={sectionRefs[10]}
          container
          spacing={3}
          width="100%"
          id="Balcony"
          paddingTop={"80px"}>
          <Grid xs={12} md={4}>
            <h3>Balcony</h3>
            <p>Outdoor dining area · Outdoor furniture · Firepit</p>
          </Grid>
          <Grid xs={12} md={8}>
            <Box className="photoContainer">
              <Box
                className="fullWidthCol"
                onClick={() => handleModifyDetailOpenModal(29)}>
                <img src="./images/balcony.webp" />
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Terrace */}
        <Grid
          // ref={sectionRefs[11]}
          container
          spacing={3}
          width="100%"
          id="Terrace"
          paddingTop={"80px"}>
          <Grid xs={12} md={4}>
            <h3>Terrace</h3>
            <p>Outdoor dining area · BBQ grill · Barbecue utensils</p>
          </Grid>
          <Grid xs={12} md={8}>
            <Box className="photoContainer">
              <Box
                className="halfCol"
                onClick={() => handleModifyDetailOpenModal(30)}>
                <img src="./images/terrace.webp" />
              </Box>
              <Box
                className="halfCol2"
                onClick={() => handleModifyDetailOpenModal(31)}>
                <img src="./images/terrace-2.webp" />
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Pool */}
        <Grid
          // ref={sectionRefs[12]}
          container
          spacing={3}
          width="100%"
          id="Pool"
          paddingTop={"80px"}>
          <Grid xs={12} md={4}>
            <h3>Pool</h3>
            <p>Outdoor dining area · BBQ grill · Barbecue utensils</p>
          </Grid>
          <Grid xs={12} md={8}>
            <Box className="photoContainer">
              <Box
                className="halfCol"
                onClick={() => handleModifyDetailOpenModal(32)}>
                <img src="./images/pool-2.webp" />
              </Box>
              <Box
                className="halfCol2"
                onClick={() => handleModifyDetailOpenModal(33)}>
                <img src="./images/pool-3.webp" />
              </Box>
              <Box
                className="fullWidthCol"
                onClick={() => handleModifyDetailOpenModal(34)}>
                <img src="./images/pool-4.webp" />
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Additional Photos*/}
        <Grid
          // ref={sectionRefs[13]}
          container
          spacing={3}
          width="100%"
          id="Additional Photos"
          paddingTop={"80px"}>
          <Grid xs={12} md={4}>
            <h3>Additional Photos</h3>
          </Grid>
          <Grid xs={12} md={8}>
            <Box className="photoContainer">
              <Box
                className="halfCol"
                onClick={() => handleModifyDetailOpenModal(35)}>
                <img src="./images/entry.webp" />
              </Box>
              <Box
                className="halfCol2"
                onClick={() => handleModifyDetailOpenModal(36)}>
                <img src="./images/additional-photo-2.webp" />
              </Box>
              <Box
                className="fullWidthCol"
                onClick={() => handleModifyDetailOpenModal(37)}>
                <img src="./images/additional-photo.webp" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Popup>
  );
};

export default PhotoTourPopup;
