import { Box, Link } from "@mui/material";
import React from "react";
import { COLORS } from "../../../SupportingFiles/colors";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

function Footer() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="32px"
      sx={{
        backgroundColor: COLORS.darkgrey,
        padding: "60px 12px 20px",
        marginTop: "80px",
        width: "100%",
      }}
    >
      <Grid container spacing={3} width="100%">
        <Grid md={3}>
          <img src="./images/dacations-logo.svg" height="120px" />
        </Grid>
        <Grid md={4} color={COLORS.white}>
          <h4 className="mb-4">We're social</h4>
          <Link
            href="https://www.instagram.com/da_cations/"
            target="_blank"
            style={{
              color: COLORS.primary,
              textDecoration: "none",
              marginRight: "16px",
            }}
          >
            <InstagramIcon style={{ height: "32px", width: "32px" }} />
          </Link>

          <Link
            href="https://www.instagram.com/da_cations/"
            target="_blank"
            style={{
              color: COLORS.primary,
              textDecoration: "none",
              marginRight: "16px",
            }}
          >
            <FacebookIcon style={{ height: "32px", width: "32px" }} />
          </Link>
          <Link
            href="https://www.linkedin.com/company/da-cations/?originalSubdomain=in"
            target="_blank"
            style={{
              color: COLORS.primary,
              textDecoration: "none",
            }}
          >
            <LinkedInIcon style={{ height: "32px", width: "32px" }} />
          </Link>
        </Grid>
        <Grid
          xs={12}
          md={5}
          justifyContent="center"
          style={{
            color: COLORS.white,
          }}
        >
          <h4 className="mb-4">Get in touch with us</h4>
          <p
            style={{
              color: COLORS.primary,
              fontSize: "16px",
            }}
          >
            +91-6283947545
          </p>{" "}
          <Link
            style={{
              color: COLORS.primary,
              textDecoration: "none",
              fontSize: "16px",
              marginTop: "12px",
              display: "inline-block",
              padding: "6px 0px",
            }}
            href="mailto:booking@dacations.in"
          >
            booking@dacations.in
          </Link>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3}
        width="100%"
        borderTop="1px solid #000"
        paddingTop="8px"
      >
        <Grid xs={12} textAlign="center">
          <p style={{ color: COLORS.white, fontSize: "16px" }}>
            Copyright © 2024 dacations. All rights reserved.
          </p>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;
