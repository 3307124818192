import React from "react";
import { Button, ButtonProps } from "@mui/material";
import { COLORS } from "../../../SupportingFiles/colors";

interface CustomButtonProps extends ButtonProps {
  text: string;
  fullWidth?: boolean;
  onClick?: () => void;
}

const Buttons: React.FC<CustomButtonProps> = ({
  text,
  fullWidth = false,
  onClick,
}) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      disableElevation
      fullWidth={fullWidth}
      sx={{
        backgroundColor: COLORS.darkgrey,
        color: COLORS.white,
        borderRadius: "8px",
        fontSize: "16px",
        padding: "20px 32px",
        lineHeight: "16px",
        textTransform: "capitalize",
        "&:hover": {
          backgroundColor: COLORS.primary,
        },
      }}
    >
      {text}
    </Button>
  );
};

export default Buttons;
